<template>
  <div>
    <div>
      <div class="card-header cardHeader-bg" style="padding: 14px;">
        <h4 class="card-title mb-0">
          {{ this.headerTittle }} ({{ this.totalRows }})
        </h4>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select>
                entries
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-right"
            >
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                  v-model="filter"
                  type="search"
                  placeholder="Search..."
                  class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
        </div>

        <div
          v-if="isLoading"
          class="d-flex justify-content-center align-items-center"
          style="height:100vh"
        >
          <img
            :src="'./images/loader.gif'"
            class="mr-2"
            style="width:100px;height:100px"
          />
        </div>

        <div class="table-responsive mb-0" v-if="!isLoading">
          <b-table
            :items="loans"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :current-page="currentPage"
            ref="selectableTable"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            show-empty
            empty-text="No record found"
            empty-filtered-text="No search result found"
          >
            <template #head(id)>
              <input v-model="isSelectAll" type="checkbox" />
            </template>
            <template v-slot:cell(id)="loans">
              <input
                type="checkbox"
                :value="loans.item.id"
                v-model="checkedNames"
              />
            </template>

            <template v-slot:cell(order_id)="loans">
              <span
                v-if="loans.item.order"
                @click="orderDetail(loans.item.order.id)"
                style="cursor: pointer;border-bottom: 2px solid red;width: 70px!important"
                title="Click Here For Show Order Detail"
                >{{ loans.item.order.unique_code }}
              </span>
            </template>
            <template v-slot:cell(assign_voucher_order_id)="loans">
              <span
                v-if="loans.item.assigVoucerByOrderid"
                @click="orderDetail(loans.item.assigVoucerByOrderid.id)"
                style="cursor: pointer;border-bottom: 2px solid red;width: 70px!important"
                title="Click Here For Show Order Detail"
                >{{ loans.item.assigVoucerByOrderid.unique_code }}
              </span>
            </template>

            <template v-slot:cell(referralBy.username)="loans">
              <span
                v-if="loans.item.voucher.type == 'Referral code'"
                @click="redirectUserVouchers(loans)"
                style="cursor: pointer;border-bottom: 2px solid green;width: 70px!important"
                title="Click Here For Show Request List"
                >{{ loans.item.referralBy.username }}
              </span>
              <span v-else>-</span>
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  @input="getResults"
                  v-model="currentPageModal"
                  :total-rows="totalRows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    loans: {
      type: Array,
      required: true,
    },
    updatedIsSelectAll: {
      type: Boolean,
    },
    isLoading: {
      type: Boolean,
    },
    rows: {
      type: Number,
      required: true,
    },
    headerTittle: {
      type: String,
      required: true,
    },
    showAddButton: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      checkedNames: [],
      currentPageModal: 1,
      isSelectAll: false,
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      fields: [
        { key: "id", label: "Select", sortable: true },
        { key: "unique_code", label: "Id", sortable: true },
        {
          key: "referralBy.username",
          label: "Referral By",
          sortable: true,
          formatter: (value) => {
            return value;
          },
        },
        {
          key: "referralTo.username",
          label: "Referral To",
          sortable: true,
          formatter: (value, key, item) => {
            return item.voucher.type == "Referral code" ? value : "-";
          },
        },

        { key: "voucher.type", label: "Voucher type", sortable: true },
        { key: "priceType", label: "Price type", sortable: true },
        {
          key: "voucher_discount",
          label: "Voucher amount",
          sortable: true,
          formatter: (value) => {
            return value.toFixed(2);
          },
        },
        {
          key: "voucher_amount",
          label: "Voucher Percentage",
          sortable: true,
          formatter: (value, key, item) => {
            return item.voucher.type == "Order discount" ? value : "-";
          },
        },
        { key: "expire_duration", label: "Duration(days)", sortable: true },
        { key: "order_id", label: "Used voucher order id", sortable: true },
        {
          key: "assign_voucher_order_id",
          label: "Assigned voucher order id",
          sortable: true,
        },
        {
          key: "start_date",
          label: "Start Date",
          sortable: true,
        },
        {
          key: "expire_date",
          label: "Expire date",
          sortable: true,
        },
        { key: "status", label: "Status" },
      ],
    };
  },

  mounted() {
    this.isSelectAll = this.unSelectedCheckbox;
    this.totalRows = this.rows;
  },

  // created: function() {
  //   console.log("sdf");
  // },

  computed: {
    // rows() {
    //   return this.loans.length;
    // },
  },

  methods: {
    getResults(page) {
      this.currentPageModal = page;
      this.refreshData();
    },
    redirectUserVouchers(object) {
      this.$router.push("/customervouchers/" + object.item.referralBy.id);
    },

    orderDetail(orderId) {
      localStorage.setItem("orderId", JSON.stringify(orderId));
      this.$router.push({ name: "orderdetail" });
    },
    refreshData() {
      this.$emit("forPagination", this.currentPageModal, this.perPage);
    },
    confirmationBulkAction(status) {
      this.$emit("confirmationBulkAction", status, this.checkedNames);
    },
    onFiltered(filteredItems) {
      if (this.filter) {
        this.totalRows = filteredItems.length;
      } else {
        this.totalRows = this.rows;
      }

      this.currentPage = 1;
    },
  },

  watch: {
    perPage() {
      this.refreshData();
    },
  },
};
</script>

<style scoped>
.btn-success {
  border-color: #5cb700;
  background-color: rgb(92, 183, 0);
}
.btn.btn-success:hover {
  border-color: #5cb700 !important;
  background-color: rgb(92, 183, 0) !important;
}
.btn-info {
  background-color: #697165;
  border-color: #697165;
}
.btn-info:hover {
  border-color: #697165 !important;
  background-color: #697165 !important;
}
.btn-primary {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}
.btn-primary:hover {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}
.btn-primary:active {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}

.btn-info:active {
  border-color: #697165 !important;
  background-color: #697165 !important;
}

.btn-primary:focus {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}

.btn-info:focus {
  border-color: #697165 !important;
  background-color: #697165 !important;
}
</style>
